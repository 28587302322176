export const IS_ASSESSMENT_REVAMP_ENABLED =
  process.env.NEXT_PUBLIC_ASSESSMENT_REVAMP_ENABLED === 'true'

export const IS_IKM_REGISTRATION_LANDING_ENABLED =
  process.env.NEXT_PUBLIC_IKM_REGISTRATION_LANDING_ENABLED === 'true'

export const IS_IKM_REGISTRATION_HOME_ENABLED =
  process.env.NEXT_PUBLIC_IKM_REGISTRATION_HOME_ENABLED === 'true'

export const IS_UJIKOM_ENTRY_HOME_ENABLED =
  process.env.NEXT_PUBLIC_UJIKOM_ENTRY_HOME_ENABLED === 'true'

export const IS_UJIKOM_ENTRY_LANDING_ENABLED =
  process.env.NEXT_PUBLIC_UJIKOM_ENTRY_LANDING_ENABLED === 'true'

export const IS_REFLEKSI_KOMPETENSI_ENTRY_HOME_ENABLED =
  process.env.NEXT_PUBLIC_REFLEKSI_KOMPETENSI_ENTRY_HOME_ENABLED === 'true'

export const IS_LMS_PMM_HOMEPAGE_ENTRY_ENABLED =
  process.env.NEXT_PUBLIC_LMS_HOMEPAGE_ENTRYPOINT === 'true'

export const IS_CONVERSATION_WITH_AI_ENABLED =
  process.env.NEXT_PUBLIC_CONVERSATION_WITH_AI_ENABLED === 'true'

export const IS_CONVERSATION_WITH_AI_MATH_FORMATTING_ENABLED =
  process.env.NEXT_PUBLIC_CONVERSATION_WITH_AI_MATH_FORMATTING_ENABLED ===
  'true'

export const IS_CONVERSATION_WITH_AI_NEW_ENTRY_POINT_ENABLED =
  process.env.NEXT_PUBLIC_CONVERSATION_WITH_AI_NEW_ENTRY_POINT_ENABLED ===
  'true'

export const IS_CONVERSATION_WITH_AI_NEW_LANDING_ENABLED =
  process.env.NEXT_PUBLIC_CONVERSATION_WITH_AI_NEW_LANDING_ENABLED === 'true'

export const IS_HOME_BANNERS_AUTOPLAY_ENABLED =
  process.env.NEXT_PUBLIC_HOME_BANNERS_AUTOPLAY_ENABLED === 'true'
