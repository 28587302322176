import {
  getDocumentPreparation,
  getEvaluationDetail,
  getFollowUpDocument,
  getFollowUpEvaluationDocument,
  getFollowUpReflectionDocument,
  getFollowUpReflectionEvaluationDocument,
  getFollowUpTargets,
  getKinerjaProfile,
  getListGuruStates,
  getObservationImplementation,
  getPelaksanaanProgress,
  getPenetapanPredikatKinerjaStep,
  getPredikatKinerjaList,
  getSupportingDocuments,
  getTargetPerilaku,
  getworkBehavior,
  upsertFollowUpEvaluationDocument,
  upsertFollowUpReflectionEvaluationDocument,
  upsertObservationImplementation,
  upsertPredikatKinerjaList,
  upsertWorkBehavior,
} from 'app/KinerjaPage/utils/demo-api'
import { handleCommonErrors, useMutationApi, useQueryApi } from 'utils/api'
import { CustomError } from 'utils/customError'
import { useKinerjaApi, useKinerjaMutationApiV2 } from './utils/api'

import type { IUseAPIGetSubjectByClassAndSchoolTypeResponse } from 'api/filters/useAPIFilters'
import type { UseMutationOptions, UseQueryOptions } from 'react-query'
import type { CommonResponse } from 'types/api'
import type {
  KonfirmasiUnorStatus,
  MultiPeriodParams,
  MultiPeriodPayload,
  RealisasiTypeV2,
  RequestKinerjaProfile,
  RequestParamsApproveSKPPeriod,
  RequestParamsGetKinerja,
  RequestParamsKinerja,
  RequestParamsRealisasi,
  RequestUploadFromBuktiKarya,
  ResponseBuktiDukung,
  ResponseContentKinerjaUtama,
  ResponseContentPerilakuKerja,
  ResponseGetListGuruByNpsn,
  ResponseGetListRealisasiV2,
  ResponseKinerja,
  ResponseKinerjaProfil,
  ResponseSKPPeriodV2,
  UnorPegawai,
  UpdateSKPPayload,
  UpdateStatePayload,
  UpsertReviewSKPPayload,
  UpsertSKPPayload,
} from 'types/kinerja'
import type {
  IFollowUpTargetItemWithModules,
  IMediaManagerResponse,
  MetaGetListGuruStates,
  ParamsConfirmPltKS,
  ParamsGetFollowUpTargets,
  ParamsGetObservationImplementation,
  ParamsGetPelaksanaanConfig,
  ParamsGetPenetapanPredikatKinerjaStep,
  ParamsGetPredikatKinerjaOrganisasi,
  ParamsPelaksanaanAppealApproval,
  ParamsPelaksanaanAppealRequest,
  ParamsPelaksanaanAppealRollback,
  ParamsSendPredikatKinerjaOrganisasi,
  ParamsUpdatePredikatKinerjaOrganisasi,
  ParamsUpsertAdditionalPredikatKinerja,
  ParamsUpsertFollowUpDocument,
  ParamsUpsertFollowUpEvaluationDocument,
  ParamsUpsertFollowUpReflectionDocument,
  ParamsUpsertFollowUpReflectionEvaluationDocument,
  ParamsUpsertObservationImplementation,
  ParamsUpsertPredikatKinerjaList,
  ParamsUpsertSupportingDocument,
  ParamsUpsertWorkBehavior,
  PelaksanaanSchool,
  RequestParamsGetMapel,
  RequestPelaksanaanDocumentPreparation,
  ResetPayload,
  ResponseEvaluationDetail,
  ResponseFollowUpDocument,
  ResponseFollowUpEvaluationDocument,
  ResponseFollowUpReflectionDocument,
  ResponseFollowUpReflectionEvaluationDocument,
  ResponseGetListGuruStates,
  ResponseGetObservationImplementation,
  ResponseGetPelaksanaanConfig,
  ResponseGetPenetapanPredikatKinerjaStep,
  ResponseGetPredikatKinerjaList,
  ResponseGetPredikatKinerjaOrganisasi,
  ResponseGetPredikatKinerjaResult,
  ResponsePelaksanaanDocumentPreparation,
  ResponsePelaksanaanProgress,
  ResponsePelaksanaanTargetPerilaku,
  ResponsePraktikPembelajaranSummary,
  ResponseSupportingDocument,
  ResponseWorkBehavior,
} from 'types/kinerja-pelaksanaan'

const basePath = '/guru/skp'

export const apiPaths = {
  kinerja: () => `${basePath}/v1/perencanaan-skp`,
  reviewKinerja: (userId: string) =>
    `${basePath}/v1/perencanaan-skp/review/${userId}`,
  realisasiBuktiDukung: (realisasiId: string) =>
    `${basePath}/v1/realisasi-skp/${realisasiId}/buktidukung`,
}

export class InvalidUserUnauthorized extends CustomError {
  constructor(apiPath: string, originalError: any) {
    let title = 'Saat ini, halaman baru tersedia untuk Guru dan Kepala Sekolah'
    let description =
      'Pastikan data “Jenis PTK” di Dapodik adalah Guru atau Kepala Sekolah. Mohon hubungi operator sekolah untuk pembaruan data diri Anda di Dapodik.'
    super({ title, description, apiPath, originalError })
  }
}

export class InvalidUserWrongAccountUsed extends CustomError {
  public alternateEmail: string = ''

  constructor(apiPath: string, originalError: any) {
    let title = 'Masuk ke akun Anda yang lain'
    let description =
      'Saat ini akun tidak memiliki akses ke Pengelolaan Kinerja. Untuk lanjut, mohon masuk ke akun yang memiliki akses ke Pengelolaan Kinerja.'
    super({ title, description, apiPath, originalError })

    this.alternateEmail = originalError.response.data.error?.message
  }
}

function handleError(status, path, error) {
  if (status === 401) {
    throw new InvalidUserUnauthorized(path, error)
  } else if (status === 403) {
    throw new InvalidUserWrongAccountUsed(path, error)
  } else {
    handleCommonErrors(status, path, error)
  }
}

function validateStatus(status: number) {
  return status < 500 && status !== 401 && status !== 403
}

export function useAPIGetCurrentPeriodV2(
  options?: UseQueryOptions<ResponseSKPPeriodV2>
) {
  return useQueryApi<ResponseSKPPeriodV2>(`${basePath}/v2/period`, {
    axiosConfig: {
      validateStatus,
    },
    queryConfig: {
      useErrorBoundary: true,
      ...options,
    },
    errorHandlerFn: handleError,
  })
}

export function useAPIPostApprovePeriod() {
  return useMutationApi<any, any, RequestParamsApproveSKPPeriod>(
    `${basePath}/v1/period-approval`,
    {
      axiosConfig: {
        method: 'POST',
      },
    }
  )
}

export function useAPIKinerja(options?: UseQueryOptions<ResponseKinerja>) {
  return useKinerjaApi<ResponseKinerja>(apiPaths.kinerja(), {
    axiosConfig: {
      validateStatus,
    },
    queryConfig: {
      useErrorBoundary: true,
      ...options,
    },
    errorHandlerFn: handleError,
  })
}

export function useAPIDeleteKinerja() {
  return useKinerjaMutationApiV2<any, any, MultiPeriodPayload>(
    apiPaths.kinerja(),
    {
      axiosConfig: {
        method: 'DELETE',
      },
    }
  )
}

export function useAPIGetReviewSkp(
  params: RequestParamsGetKinerja,
  { enabled = true } = {}
) {
  return useKinerjaApi<ResponseKinerja>(apiPaths.reviewKinerja(params.ptkId), {
    axiosConfig: {
      validateStatus,
    },
    queryConfig: {
      enabled: !!params.ptkId && enabled,
      useErrorBoundary: true,
    },
    errorHandlerFn: handleError,
  })
}

export const API_PATH_KINERJA_PROFILE = `${basePath}/v1/guru/profile`

export function useAPIKinerjaProfile({
  params,
  options,
  isDemoMode = false,
}: RequestKinerjaProfile = {}) {
  return useQueryApi<ResponseKinerjaProfil>(API_PATH_KINERJA_PROFILE, {
    ...(isDemoMode
      ? {
          queryFn: getKinerjaProfile,
          queryKey: 'demo-getKinerjaProfile',
        }
      : {}),
    axiosConfig: {
      validateStatus,
      params,
    },
    queryConfig: {
      useErrorBoundary: true,
      ...options,
    },
    errorHandlerFn: handleError,
  })
}

export function useAPIContentKinerjaUtama(params: RequestParamsKinerja = {}) {
  const jenjang = params.jenjang?.length === 0 ? 'UNDEFINED' : params.jenjang
  const axiosParams = params.ptkId ? { ptkId: params.ptkId } : {}

  return useKinerjaApi<ResponseContentKinerjaUtama>(
    `${basePath}/v1/konten/kinerja/${jenjang}`,
    {
      axiosConfig: {
        validateStatus,
        params: axiosParams,
      },
      queryConfig: {
        enabled: !!jenjang,
        useErrorBoundary: true,
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIContentPerilakuKerja(params: RequestParamsKinerja = {}) {
  const axiosParams = params.ptkId ? { ptkId: params.ptkId } : {}
  return useQueryApi<ResponseContentPerilakuKerja>(
    `${basePath}/v1/konten/perilaku-kerja`,
    {
      axiosConfig: {
        validateStatus,
        params: axiosParams,
      },
      queryConfig: {
        useErrorBoundary: true,
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIUpsertSKP() {
  return useKinerjaMutationApiV2<any, any, UpsertSKPPayload>(
    `${basePath}/v1/perencanaan-skp`,
    {
      axiosConfig: {
        method: 'POST',
      },
    }
  )
}

export function useAPIGetListGuruByNpsn(npsn: string, { enabled = true } = {}) {
  return useKinerjaApi<ResponseGetListGuruByNpsn>(
    `${basePath}/v1/guru/npsn/${npsn}`,
    {
      axiosConfig: {
        validateStatus,
      },
      queryConfig: {
        enabled: !!npsn && enabled,
        useErrorBoundary: true,
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIUpsertReviewSKP() {
  return useKinerjaMutationApiV2<
    any,
    any,
    UpsertReviewSKPPayload & MultiPeriodPayload
  >(`${basePath}/v1/perencanaan-skp/review`, {
    axiosConfig: {
      method: 'PATCH',
    },
  })
}

export function useAPIUpdateState() {
  return useMutationApi<any, any, UpdateStatePayload>(
    `${basePath}/v1/perencanaan-skp/update/state`,
    {
      axiosConfig: {
        method: 'PATCH',
      },
    }
  )
}

export function useAPIUpdatePengembanganKompetensi() {
  return useKinerjaMutationApiV2<
    any,
    any,
    Pick<UpdateSKPPayload, 'pengembanganKompetensi' | 'skpPeriod' | 'skpYear'>
  >(`${basePath}/v1/perencanaan-skp/update/pk`, {
    axiosConfig: {
      method: 'PATCH',
    },
  })
}

export function useAPIUpdateTugasTambahan() {
  return useKinerjaMutationApiV2<
    any,
    any,
    Pick<UpdateSKPPayload, 'tugasTambahan' | 'skpPeriod' | 'skpYear'>
  >(`${basePath}/v1/perencanaan-skp/update/tt`, {
    axiosConfig: {
      method: 'PATCH',
    },
  })
}

export function useAPIGetListRealisasiV2(
  category: RealisasiTypeV2 = 'KU',
  periodParams?: MultiPeriodParams
) {
  return useKinerjaApi<ResponseGetListRealisasiV2>(
    `${basePath}/v1/realisasi-skp`,
    {
      axiosConfig: {
        params: {
          kategori: category,
          ...periodParams,
        },
        validateStatus,
      },
      queryConfig: {
        enabled: !!category,
        useErrorBoundary: true,
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIGetRealisasiSKP({
  params = { kategori: ['TT'] },
  options,
}: {
  params?: {
    userId?: string // @deprecated
    ptkId?: string
    kategori?: Array<RealisasiTypeV2>
  }
  options?: UseQueryOptions<ResponseGetListRealisasiV2>
}) {
  return useKinerjaApi<ResponseGetListRealisasiV2>(
    `${basePath}/v1/realisasi-skp`,
    {
      queryConfig: {
        ...options,
      },
      axiosConfig: {
        params,
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIGetBuktiDukung(params: RequestParamsRealisasi) {
  return useKinerjaApi<ResponseBuktiDukung>(
    apiPaths.realisasiBuktiDukung(params.realisasiId),
    {
      axiosConfig: {
        validateStatus,
        params: { ...params.periodParams },
      },
      queryConfig: {
        useErrorBoundary: true,
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIGetBuktiDukungRealisasi({
  params,
  options,
}: {
  params?: {
    realisasiId: string
    ptkId?: string
  }
  options?: UseQueryOptions<ResponseBuktiDukung>
}) {
  return useKinerjaApi<ResponseBuktiDukung>(
    apiPaths.realisasiBuktiDukung(params.realisasiId),
    {
      queryConfig: {
        ...options,
      },
      axiosConfig: {
        params: { ptkId: params.ptkId },
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIUploadBuktiDukung() {
  return useMutationApi<any, any, FormData>(
    `${basePath}/v1/realisasi-skp/upload/buktidukung`,
    {
      axiosConfig: {
        method: 'PATCH',
      },
    }
  )
}

export function useAPIUploadFromBuktiKarya() {
  return useKinerjaMutationApiV2<any, any, RequestUploadFromBuktiKarya>(
    `${basePath}/v1/realisasi-skp/upload/buktidukung/buktikarya/pdf`,
    {
      axiosConfig: {
        method: 'PATCH',
      },
    }
  )
}

export function useAPIPelaksanaanTargetPerilaku(
  praktikPembelajaranId: string,
  opts?: UseQueryOptions<CommonResponse<ResponsePelaksanaanTargetPerilaku[]>>,
  isDemoMode = false
) {
  return useQueryApi<CommonResponse<ResponsePelaksanaanTargetPerilaku[]>>(
    `${basePath}/v1/pelaksanaan/target-perilaku/${praktikPembelajaranId}`,
    {
      ...(isDemoMode
        ? {
            queryFn: getTargetPerilaku,
            queryKey: 'demo-getTargetPerilaku',
          }
        : {}),
      queryConfig: {
        ...opts,
      },
    }
  )
}

export const API_PATH_PELAKSANAAN_PROGRESS = `${basePath}/v1/pelaksanaan/progress`

export function useAPIPelaksanaanProgress({
  userId,
  opts,
  ptkId,
  isDemoMode = false,
  periodParams,
}: {
  userId?: string // @deprecated
  ptkId?: string
  opts?: UseQueryOptions<CommonResponse<ResponsePelaksanaanProgress>>
  isDemoMode?: boolean
  periodParams?: MultiPeriodParams
} = {}) {
  return useKinerjaApi<CommonResponse<ResponsePelaksanaanProgress>>(
    API_PATH_PELAKSANAAN_PROGRESS,
    {
      ...(isDemoMode
        ? {
            queryFn: getPelaksanaanProgress,
            queryKey: 'demo-getPelaksanaanProgress',
          }
        : {}),
      axiosConfig: {
        params: {
          userId,
          ptkId,
          ...periodParams,
        },
      },
      queryConfig: {
        ...opts,
      },
    }
  )
}

export const useAPIGetSubjectByClassAndSchoolType = ({
  kelas,
  fase,
  opts,
}: RequestParamsGetMapel) => {
  return useQueryApi<{ data: IUseAPIGetSubjectByClassAndSchoolTypeResponse }>(
    '/curriculums/v2/public/subjects',
    {
      queryConfig: {
        staleTime: 3600 * 1000,
        cacheTime: 3600 * 1000,
        ...opts,
      },
      axiosConfig: {
        params: {
          class: kelas,
          school_type: fase,
        },
      },
    }
  )
}

export function useAPIPelaksanaanDocumentPreparation({
  userId,
  ptkId,
  opts,
  isDemoMode = false,
}: {
  userId?: string // @deprecated
  ptkId?: string
  opts?: UseQueryOptions<CommonResponse<ResponsePelaksanaanDocumentPreparation>>
  isDemoMode?: boolean
}) {
  return useKinerjaApi<CommonResponse<ResponsePelaksanaanDocumentPreparation>>(
    `${basePath}/v1/pelaksanaan/document-preparation`,
    {
      ...(isDemoMode
        ? {
            queryFn: getDocumentPreparation,
            queryKey: 'demo-getDocumentPreparation',
          }
        : {}),
      axiosConfig: {
        params: {
          userId,
          ptkId,
        },
      },
      queryConfig: {
        ...opts,
      },
    }
  )
}

export function useAPIUpsertPelaksanaanDocumentPreparation() {
  return useKinerjaMutationApiV2<
    any,
    any,
    RequestPelaksanaanDocumentPreparation
  >(`${basePath}/v1/pelaksanaan/document-preparation`, {
    axiosConfig: {
      method: 'POST',
    },
  })
}

export function useAPIGetObservationImplementation(
  params: ParamsGetObservationImplementation,
  opts?: UseQueryOptions<CommonResponse<ResponseGetObservationImplementation>>,
  isDemoMode?: boolean
) {
  return useKinerjaApi<CommonResponse<ResponseGetObservationImplementation>>(
    `${basePath}/v1/pelaksanaan/observation-implementation`,
    {
      ...(isDemoMode
        ? {
            queryFn: getObservationImplementation,
            queryKey: 'demo-getObservationImplementation',
          }
        : {}),
      queryConfig: {
        ...opts,
      },
      axiosConfig: {
        params: { ...params },
      },
    }
  )
}

export function useAPIUpsertObservationImplementation(
  userId: string,
  opts?: UseMutationOptions<
    unknown,
    Error,
    ParamsUpsertObservationImplementation
  >,
  isDemoMode?: boolean
) {
  return useKinerjaMutationApiV2(
    `${basePath}/v1/pelaksanaan/observation-implementation/${userId}`,
    {
      ...(isDemoMode
        ? {
            mutationFn: upsertObservationImplementation,
          }
        : {}),
      axiosConfig: {
        method: 'POST',
      },
      mutationConfig: {
        ...opts,
      },
    }
  )
}

export function useAPIGetFollowUpTargets(
  params: ParamsGetFollowUpTargets,
  opts?: UseQueryOptions<CommonResponse<Array<IFollowUpTargetItemWithModules>>>,
  isDemoMode = false
) {
  return useQueryApi<CommonResponse<Array<IFollowUpTargetItemWithModules>>>(
    `${basePath}/v1/pelaksanaan/tujuan-tindak-lanjut/${params.praktikPembelajaranId}`,
    {
      ...(isDemoMode
        ? {
            queryFn: getFollowUpTargets,
            queryKey: 'demo-getFollowUpTargets',
          }
        : {}),
      queryConfig: {
        ...opts,
      },
    }
  )
}

export function useAPIUpsertFollowUpDocument(
  opts?: UseMutationOptions<unknown, Error, ParamsUpsertFollowUpDocument>
) {
  return useKinerjaMutationApiV2(
    `${basePath}/v1/pelaksanaan/document-follow-up`,
    {
      axiosConfig: {
        method: 'POST',
      },
      mutationConfig: {
        ...opts,
      },
    }
  )
}

export const API_GET_FOLLOW_UP_DOCUMENT_QUERY_KEY =
  'API_GET_FOLLOW_UP_DOCUMENT_QUERY_KEY'
export function useAPIGetFollowUpDocument({
  params,
  options,
  isDemoMode = false,
}: {
  params?: {
    userId?: string // @deprecated
    ptkId?: string
  }
  options?: UseQueryOptions<CommonResponse<ResponseFollowUpDocument>>
  isDemoMode?: boolean
}) {
  return useKinerjaApi<CommonResponse<ResponseFollowUpDocument>>(
    `${basePath}/v1/pelaksanaan/document-follow-up`,
    {
      ...(isDemoMode
        ? {
            queryFn: getFollowUpDocument,
            queryKey: 'demo-getFollowUpDocument',
          }
        : {}),
      queryConfig: {
        queryKey: API_GET_FOLLOW_UP_DOCUMENT_QUERY_KEY,
        ...options,
      },
      axiosConfig: {
        params: { ...params },
      },
    }
  )
}

export function useAPIUploadMediaManager() {
  return useMutationApi<CommonResponse<IMediaManagerResponse[]>, any, FormData>(
    `${basePath}/v1/media-manager/upload`,
    {
      axiosConfig: {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    }
  )
}

export function useAPIGetFollowUpReflectionDocument({
  params,
  options,
  isDemoMode = false,
}: {
  params?: {
    userId?: string // @deprecated
    ptkId?: string
  }
  options?: UseQueryOptions<CommonResponse<ResponseFollowUpReflectionDocument>>
  isDemoMode?: boolean
}) {
  return useKinerjaApi<CommonResponse<ResponseFollowUpReflectionDocument>>(
    `${basePath}/v1/pelaksanaan/document-reflection-follow-up`,
    {
      ...(isDemoMode
        ? {
            queryFn: getFollowUpReflectionDocument,
            queryKey: 'demo-getFollowUpReflectionDocument',
          }
        : {}),
      queryConfig: {
        ...options,
      },
      axiosConfig: {
        params,
      },
    }
  )
}

export function useAPIUpsertFollowUpReflectionDocument(
  opts?: UseMutationOptions<
    unknown,
    Error,
    ParamsUpsertFollowUpReflectionDocument
  >
) {
  return useKinerjaMutationApiV2(
    `${basePath}/v1/pelaksanaan/document-reflection-follow-up`,
    {
      axiosConfig: {
        method: 'POST',
      },
      mutationConfig: {
        ...opts,
      },
    }
  )
}

export function useAPIUpsertFollowUpEvaluationDocument(
  userId: string,
  opts?: UseMutationOptions<
    unknown,
    Error,
    ParamsUpsertFollowUpEvaluationDocument
  >,
  isDemoMode = false
) {
  return useKinerjaMutationApiV2(
    `${basePath}/v1/pelaksanaan/document-follow-up-evaluation/${userId}`,
    {
      ...(isDemoMode
        ? {
            mutationFn: upsertFollowUpEvaluationDocument,
          }
        : {}),
      axiosConfig: {
        method: 'POST',
      },
      mutationConfig: {
        ...opts,
      },
    }
  )
}

export function useAPIGetFollowUpEvaluationDocument({
  params,
  options,
  isDemoMode = false,
}: {
  params?: {
    userId?: string // @deprecated
    ptkId?: string
  }
  options?: UseQueryOptions<CommonResponse<ResponseFollowUpEvaluationDocument>>
  isDemoMode?: boolean
}) {
  return useKinerjaApi(
    `${basePath}/v1/pelaksanaan/document-follow-up-evaluation`,
    {
      ...(isDemoMode
        ? {
            queryFn: getFollowUpEvaluationDocument,
            queryKey: 'demo-getFollowUpEvaluationDocument',
          }
        : {}),
      queryConfig: {
        ...options,
      },
      axiosConfig: {
        params,
      },
    }
  )
}

export function useAPIUpsertFollowUpReflectionEvaluationDocument(
  userId: string,
  opts?: UseMutationOptions<
    unknown,
    Error,
    ParamsUpsertFollowUpReflectionEvaluationDocument
  >,
  isDemoMode?: boolean
) {
  return useKinerjaMutationApiV2(
    `${basePath}/v1/pelaksanaan/document-reflection-follow-up-evaluation/${userId}`,
    {
      ...(isDemoMode
        ? {
            mutationFn: upsertFollowUpReflectionEvaluationDocument,
          }
        : {}),
      axiosConfig: {
        method: 'POST',
      },
      mutationConfig: {
        ...opts,
      },
    }
  )
}

export function useAPIGetFollowUpReflectionEvaluationDocument({
  params,
  options,
  isDemoMode,
}: {
  params?: {
    userId?: string // @deprecated
    ptkId?: string
  }
  options?: UseQueryOptions<
    CommonResponse<ResponseFollowUpReflectionEvaluationDocument>
  >
  isDemoMode?: boolean
}) {
  return useKinerjaApi<
    CommonResponse<ResponseFollowUpReflectionEvaluationDocument>
  >(`${basePath}/v1/pelaksanaan/document-reflection-follow-up-evaluation`, {
    ...(isDemoMode
      ? {
          queryFn: getFollowUpReflectionEvaluationDocument,
          queryKey: 'demo-getFollowUpReflectionEvaluationDocument',
        }
      : {}),
    queryConfig: {
      ...options,
    },
    axiosConfig: {
      params,
    },
  })
}

export const API_PATH_SUPPORTING_DOCUMENT = `${basePath}/v1/pelaksanaan/supporting-document`
export function useAPIGetSupportingDocument({
  options,
  params,
  isDemoMode = false,
}: {
  params?: {
    npsn?: string
  }
  options?: UseQueryOptions<CommonResponse<ResponseSupportingDocument>>
  isDemoMode?: boolean
}) {
  return useKinerjaApi<CommonResponse<ResponseSupportingDocument>>(
    API_PATH_SUPPORTING_DOCUMENT,
    {
      ...(isDemoMode
        ? {
            queryFn: getSupportingDocuments,
            queryKey: 'demo-getSupportingDocuments',
          }
        : {}),
      queryConfig: {
        ...options,
      },
      axiosConfig: {
        params,
      },
    }
  )
}

export function useAPIUpsertSupportingDocument(
  npsn: string,
  opts?: UseMutationOptions<unknown, Error, ParamsUpsertSupportingDocument>
) {
  return useKinerjaMutationApiV2(`${API_PATH_SUPPORTING_DOCUMENT}/${npsn}`, {
    axiosConfig: {
      method: 'POST',
    },
    mutationConfig: {
      ...opts,
    },
  })
}

export const API_PATH_LIST_GURU_STATES = `${basePath}/v1/pelaksanaan/list-guru/states`
export function useAPIGetListGuruStates({
  options,
  npsn,
  isDemoMode = false,
}: {
  npsn: string
  options?: UseQueryOptions<
    CommonResponse<ResponseGetListGuruStates, MetaGetListGuruStates>
  >
  isDemoMode?: boolean
}) {
  return useKinerjaApi<
    CommonResponse<ResponseGetListGuruStates, MetaGetListGuruStates>
  >(`${API_PATH_LIST_GURU_STATES}/${npsn}`, {
    ...(isDemoMode
      ? { queryFn: getListGuruStates, queryKey: 'demo-getListGuruStates' }
      : {}),
    queryConfig: {
      ...options,
    },
  })
}

export const API_PATH_PRAKTIK_PEMBELAJARAN_SUMMARY = `${basePath}/v1/konten/praktik-pembelajaran/summary`
export function useAPIGetPraktikPembelajaranSummary({
  npsn,
  options,
}: {
  npsn: string
  options?: UseQueryOptions<CommonResponse<ResponsePraktikPembelajaranSummary>>
}) {
  return useKinerjaApi<CommonResponse<ResponsePraktikPembelajaranSummary>>(
    `${API_PATH_PRAKTIK_PEMBELAJARAN_SUMMARY}/${npsn}`,
    {
      queryConfig: {
        ...options,
      },
    }
  )
}

export const API_PATH_EVALUATION_DETAIL = `${basePath}/v1/pelaksanaan/guru/details`
export function useAPIGetEvaluationDetail({
  params,
  options,
  isDemoMode = false,
}: {
  options?: UseQueryOptions<CommonResponse<ResponseEvaluationDetail>>
  params: { ptkId: string }
  isDemoMode?: boolean
}) {
  return useKinerjaApi<CommonResponse<ResponseEvaluationDetail>>(
    `${API_PATH_EVALUATION_DETAIL}/${params.ptkId}`,
    {
      ...(isDemoMode
        ? {
            queryFn: getEvaluationDetail,
            queryKey: 'demo-getEvaluationDetail',
          }
        : {}),
      queryConfig: {
        ...options,
      },
      errorHandlerFn: handleError,
    }
  )
}

export const API_PATH_PELAKSANAAN_SCHOOLS = `${basePath}/v1/pelaksanaan/schools`
export function useAPIPelaksanaanSchools({
  options,
}: {
  options?: UseQueryOptions<CommonResponse<PelaksanaanSchool[]>>
}) {
  return useKinerjaApi<CommonResponse<PelaksanaanSchool[]>>(
    API_PATH_PELAKSANAAN_SCHOOLS,
    {
      queryConfig: {
        ...options,
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIPelaksanaanReset(ptkId: string) {
  return useKinerjaMutationApiV2<any, CustomError, ResetPayload>(
    `${basePath}/v1/pelaksanaan/reset/${ptkId}`,
    {
      axiosConfig: {
        method: 'POST',
      },
    }
  )
}

export function useAPIConfirmPltKS(
  opts?: UseMutationOptions<unknown, Error, ParamsConfirmPltKS>
) {
  return useMutationApi(`${basePath}/v1/guru/confirm-ks-plt`, {
    axiosConfig: {
      method: 'POST',
    },
    mutationConfig: {
      ...opts,
    },
  })
}

export const API_PATH_WORK_BEHAVIOR = `${basePath}/v1/penilaian/perilaku-kerja`
export function useAPIGetWorkBehavior({
  params,
  options,
  isDemoMode = false,
}: {
  options?: UseQueryOptions<CommonResponse<ResponseWorkBehavior>>
  params: { ptkId: string }
  isDemoMode?: boolean
}) {
  return useKinerjaApi<CommonResponse<ResponseWorkBehavior>>(
    `${API_PATH_WORK_BEHAVIOR}/${params.ptkId}`,
    {
      ...(isDemoMode
        ? {
            queryFn: getworkBehavior,
            queryKey: 'demo-getWorkBehavior',
          }
        : {}),
      queryConfig: {
        ...options,
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIUpsertWorkBehavior(
  ptkId: string,
  opts?: UseMutationOptions<unknown, Error, ParamsUpsertWorkBehavior>,
  isDemoMode = false
) {
  return useKinerjaMutationApiV2(`${API_PATH_WORK_BEHAVIOR}/${ptkId}`, {
    ...(isDemoMode
      ? {
          mutationFn: upsertWorkBehavior,
        }
      : {}),
    axiosConfig: {
      method: 'POST',
    },
    mutationConfig: {
      ...opts,
    },
  })
}

export function useAPIPostPelaksanaanAppealApproval(
  notificationId: string,
  opts?: UseMutationOptions<unknown, Error, ParamsPelaksanaanAppealApproval>
) {
  return useKinerjaMutationApiV2(
    `${basePath}/v1/pelaksanaan-appeal/state/rollback-request/${notificationId}`,
    {
      axiosConfig: {
        method: 'POST',
      },
      mutationConfig: {
        ...opts,
      },
    }
  )
}

export function useAPIPostPelaksanaanAppealRequest(
  opts?: UseMutationOptions<unknown, Error, ParamsPelaksanaanAppealRequest>
) {
  return useKinerjaMutationApiV2(
    `${basePath}/v1/pelaksanaan-appeal/state/rollback-request`,
    {
      axiosConfig: {
        method: 'POST',
      },
      mutationConfig: {
        ...opts,
      },
    }
  )
}

export function useAPIPostPelaksanaanAppealRollback(
  opts?: UseMutationOptions<unknown, Error, ParamsPelaksanaanAppealRollback>
) {
  return useKinerjaMutationApiV2(
    `${basePath}/v1/pelaksanaan-appeal/state/rollback`,
    {
      axiosConfig: {
        method: 'POST',
      },
      mutationConfig: {
        ...opts,
      },
    }
  )
}

export const API_PATH_PREDIKAT_KINERJA_LIST = `${basePath}/v1/predikat-kinerja/list-guru`
export function useAPIGetPredikatKinerjaList({
  options,
  params,
  isDemoMode = false,
}: {
  options?: UseQueryOptions<ResponseGetPredikatKinerjaList>
  params: { npsn: string }
  isDemoMode?: boolean
}) {
  return useKinerjaApi<ResponseGetPredikatKinerjaList>(
    `${API_PATH_PREDIKAT_KINERJA_LIST}/${params.npsn}`,
    {
      ...(isDemoMode
        ? {
            queryFn: getPredikatKinerjaList,
            queryKey: 'demo-getPredikatKinerjaList',
          }
        : {}),
      queryConfig: {
        ...options,
      },
    }
  )
}

export function useAPIUpsertPredikatKinerjaList(
  npsn: string,
  opts?: UseMutationOptions<unknown, Error, ParamsUpsertPredikatKinerjaList>,
  isDemoMode = false
) {
  return useKinerjaMutationApiV2(
    `${basePath}/v1/predikat-kinerja/submit/${npsn}`,
    {
      ...(isDemoMode
        ? {
            mutationFn: upsertPredikatKinerjaList,
          }
        : {}),
      axiosConfig: {
        method: 'POST',
      },
      mutationConfig: {
        ...opts,
      },
    }
  )
}

export const API_PATH_PREDIKAT_KINERJA_STEP = `${basePath}/v1/predikat-kinerja/steps`
export function useAPIGetPenetapanPredikatKinerjaStep({
  options,
  params,
  isDemoMode = false,
}: {
  options?: UseQueryOptions<ResponseGetPenetapanPredikatKinerjaStep>
  params: ParamsGetPenetapanPredikatKinerjaStep
  isDemoMode?: boolean
}) {
  return useKinerjaApi<ResponseGetPenetapanPredikatKinerjaStep>(
    `${API_PATH_PREDIKAT_KINERJA_STEP}/${params.npsn}`,
    {
      ...(isDemoMode
        ? {
            queryFn: getPenetapanPredikatKinerjaStep,
            queryKey: 'demo-getPenetapanPredikatKinerjaStep',
          }
        : {}),
      queryConfig: {
        ...options,
      },
      axiosConfig: {
        params: {
          period: params.periode,
          year: params.year,
        },
      },
    }
  )
}

export const API_PATH_PELAKSANAAN_CONFIG = `${basePath}/v1/pelaksanaan/static-config`
export function useAPIGetPelaksanaanConfig({
  options,
  params,
}: {
  options?: UseQueryOptions<ResponseGetPelaksanaanConfig>
  params: ParamsGetPelaksanaanConfig
}) {
  return useKinerjaApi<ResponseGetPelaksanaanConfig>(
    `${API_PATH_PELAKSANAAN_CONFIG}/${params.key}`,
    {
      queryConfig: {
        ...options,
      },
      axiosConfig: {
        params: {
          period: params.periode,
          year: params.year,
        },
      },
    }
  )
}

export function useAPISendPredikatKinerjaOrganisasi(
  opts?: UseMutationOptions<unknown, Error, ParamsSendPredikatKinerjaOrganisasi>
) {
  return useMutationApi(`${basePath}/v1/predikat-organisasi/send`, {
    axiosConfig: {
      method: 'POST',
    },
    mutationConfig: {
      ...opts,
    },
  })
}

export function useAPIGetUnorData(
  opts?: UseQueryOptions<CommonResponse<UnorPegawai>>
) {
  return useQueryApi<CommonResponse<UnorPegawai>>(
    `${basePath}/v1/unor-pegawai`,
    {
      axiosConfig: {
        validateStatus,
      },
      queryConfig: {
        useErrorBoundary: true,
        ...opts,
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIPatchConfirmUnor(
  opts?: UseMutationOptions<
    unknown,
    Error,
    { statusUnor: KonfirmasiUnorStatus }
  >
) {
  return useMutationApi(`${apiPaths.kinerja()}/konfirmasi-unor`, {
    axiosConfig: {
      method: 'PATCH',
    },
    mutationConfig: {
      ...opts,
    },
  })
}

export function useAPIUpdatePredikatKinerjaOrganisasi(
  opts?: UseMutationOptions<
    unknown,
    Error,
    ParamsUpdatePredikatKinerjaOrganisasi
  >
) {
  return useMutationApi(`${basePath}/v1/predikat-organisasi/modify`, {
    axiosConfig: {
      method: 'PATCH',
    },
    mutationConfig: {
      ...opts,
    },
  })
}

export const API_PATH_PREDIKAT_KINERJA_ORGANISASI = `${basePath}/v1/predikat-organisasi`

export function useAPIGetPredikatKinerjaOrganisasi(
  params: ParamsGetPredikatKinerjaOrganisasi,
  opts?: UseQueryOptions<ResponseGetPredikatKinerjaOrganisasi>
) {
  return useKinerjaApi<ResponseGetPredikatKinerjaOrganisasi>(
    API_PATH_PREDIKAT_KINERJA_ORGANISASI,
    {
      queryConfig: {
        ...opts,
      },
      axiosConfig: {
        params,
      },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIGetPredikatKinerjaResult({
  options,
  params,
}: {
  options?: UseQueryOptions<CommonResponse<ResponseGetPredikatKinerjaResult>>
  params?: MultiPeriodParams
}) {
  return useKinerjaApi<CommonResponse<ResponseGetPredikatKinerjaResult>>(
    `${basePath}/v1/predikat-kinerja/result`,
    {
      queryConfig: {
        ...options,
      },
      axiosConfig: { params },
      errorHandlerFn: handleError,
    }
  )
}

export function useAPIUpsertAdditionalPredikatKinerja(
  npsn: string,
  opts?: UseMutationOptions<
    unknown,
    Error,
    ParamsUpsertAdditionalPredikatKinerja
  >
) {
  return useKinerjaMutationApiV2(
    `${basePath}/v1/predikat-kinerja/additional-submit-guru/${npsn}`,
    {
      axiosConfig: {
        method: 'POST',
      },
      mutationConfig: {
        ...opts,
      },
    }
  )
}

export function useAPIPredikatKinerjaReset(npsn: string) {
  return useKinerjaMutationApiV2<any, CustomError, any>(
    `${basePath}/v1/predikat-kinerja/reset/${npsn}`,
    {
      axiosConfig: {
        method: 'POST',
      },
    }
  )
}
