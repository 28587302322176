const basePath = '/pengelolaan-kinerja'

export const PENGELOLAAN_KINERJA_BASE_PATH = basePath

// Beranda
export const PENGELOLAAN_KINERJA_BERANDA_PATH = PENGELOLAAN_KINERJA_BASE_PATH

// Guru
export const PENGELOLAAN_KINERJA_GURU_PATH = `${PENGELOLAAN_KINERJA_BASE_PATH}/guru`

// Kepala Sekolah
export const PENGELOLAAN_KINERJA_KS_PENILAI_PATH = `${PENGELOLAAN_KINERJA_BASE_PATH}/kepala-sekolah/penilai`
export const PENGELOLAAN_KINERJA_KS_PEGAWAI_PATH = `${PENGELOLAAN_KINERJA_BASE_PATH}/kepala-sekolah/pegawai`

// Pengawas Sekolah
export const PENGELOLAAN_KINERJA_PS_PATH = `${PENGELOLAAN_KINERJA_BASE_PATH}/pengawas-sekolah`

// Dinas Pendidikan
export const PENGELOLAAN_KINERJA_KADIN_PATH = `${PENGELOLAAN_KINERJA_BASE_PATH}/kepala-dinas`
export const PENGELOLAAN_KINERJA_KADIN_PEMBERI_PREDIKAT_PATH = `${PENGELOLAAN_KINERJA_KADIN_PATH}/pemberi-predikat-kinerja-organisasi`

// Notifikasi
export const PENGELOLAAN_KINERJA_NOTIFIKASI_PATH = `${PENGELOLAAN_KINERJA_BASE_PATH}/notifikasi`

// Riwayat
export const PENGELOLAAN_KINERJA_RIWAYAT_PATH = `${PENGELOLAAN_KINERJA_BASE_PATH}/riwayat`
export const PENGELOLAAN_KINERJA_RIWAYAT_KS_PATH = `${PENGELOLAAN_KINERJA_BASE_PATH}/riwayat/[riwayatId]`
export const PENGELOLAAN_KINERJA_RIWAYAT_GURU_PATH = `${PENGELOLAAN_KINERJA_RIWAYAT_PATH}/guru`
