import clsx from 'clsx'
import { useRouter } from 'next/router'

import { Button } from '@wartek-id/button-v1'
import { Icon } from '@wartek-id/icon-v2'
import { Text } from '@wartek-id/text-v1'

type HeaderProps = {
  title: string
  rightNode?: React.ReactNode
  className?: string
  backTestId?: string
  onBack?: () => void
}

export function Header({
  className,
  title,
  rightNode = null,
  backTestId,
  onBack,
}: HeaderProps) {
  const router = useRouter()

  const handleBack = () => {
    if (typeof onBack === 'function') {
      onBack()
    } else {
      router.back()
    }
  }

  return (
    <div className={clsx('flex w-full items-center space-x-5', className)}>
      <div className="flex flex-1 space-x-3">
        <Button
          variant="ghost"
          iconOnly
          className="flex-none"
          data-testid={backTestId}
          onClick={handleBack}
        >
          {/*
           * Note: we need to explicitly set font-size and line-height as workaround of styles collision
           * due to multiple version of @wartek-id/icon being used in this project.
           */}
          <Icon className="text-black !text-4xl !leading-9" fontSize="lg">
            arrow_back
          </Icon>
          <span className="sr-only">Kembali</span>
        </Button>
        <Text variant="display-md" className="text-black" as="h1">
          {title}
        </Text>
      </div>
      {rightNode}
    </div>
  )
}
